<template>
  <div class="home_page">
    <Navbar />
    <div class="container">
      <div class="contact">
        <div class="contact__box">
          <h3>SMD Consulting Sp. z o.o.</h3>
          <p>ul. Świdnicka 12</p>
          <p>50-068 Wrocław</p>
          <p>898-221-59-52</p>
          <p>tel. 698 165 319</p>
          <form
            action="https://formspree.io/f/mjvjqzdl"
            method="POST"
            enctype="multipart/form-data"
          >
            <div class="form-group">
              <label for="email">Adres email</label>
              <input type="email" name="email" id="email" />
            </div>
            <div class="form-group">
              <label for="category">Jestem zainteresowana/y/</label>
              <select name="category" id="category">
                <option value="Audyty projektów unijnych">
                  Audyty projektów unijnych
                </option>
                <option value="Rozliczenie projektów unijnych">
                  Rozliczenie projektów unijnych
                </option>
                <option value="Usługi księgowości">Usługi księgowości</option>
                <option value="Doradztwo biznesowe">Doradztwo biznesowe</option>
                <option value="Usługi prawne">Usługi prawne</option>
                <option value="Inne">Inne</option>
              </select>
            </div>
            <div class="form-group">
              <label for="content">Treść</label>
              <textarea style="resize: none" id="content" name="content" />
            </div>
            <button type="submit" class="submit-btn">Wyślij</button>
          </form>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import Navbar from "./Navbar.vue";
export default {
  name: "Contact",
  components: { Navbar },
  setup() {
    return {};
  },
};
</script>

<!-- Add "scoped" attribute to limit CSS to this component only -->
<style scoped lang="scss">
h3 {
  font-size: 32px;
  margin-bottom: 16px;
}

.contact {
  max-width: 650px;
  margin: 0 auto;
  width: 100%;
}
.contact__box {
  text-align: left;
  color: #fff;
  background: rgba(0, 0, 0, 0.3);
  object-fit: contain;
  padding: 16px;
}

.contact__button {
  cursor: pointer;
  background: #fff;
  border: none;
  padding: 10px 20px;
  margin-top: 16px;
  a {
    text-decoration: none;
    color: black;
    font-family: "Poppins";
  }
}

form {
  margin-top: 24px;
}

.form-group {
  display: flex;
  flex-direction: column;
  margin-bottom: 16px;
}

input {
  padding: 10px;
  border-radius: 6px;
  border: none;
  outline: none;
}
select {
  padding: 10px;
  border-radius: 6px;
  border: none;
  background: white;
  outline: none;
}
textarea {
  padding: 10px;
  border-radius: 6px;
  height: 60px;
  outline: none;
}

.submit-btn {
  padding: 10px 40px;
  display: block;
  border-radius: 6px;
  font-size: 18px;
  border: none;
  cursor: pointer;
  background-color: #f0a53d;
  font-family: "Poppins", sans-serif;
  margin-left: auto;
  &:hover {
    background-color: #dd8912;
    transition: 0.2s;
  }
}

#category {
  cursor: pointer;
}
</style>
