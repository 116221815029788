<template>
  <Widget />
  <div class="legal_page">
    <Navbar />
  </div>
  <div class="legal_content">
    <div class="container">
      <h2 class="about_title">Kim jesteśmy?</h2>
      <p class="about">
        SMD Eco jest Spółką consultingową specjalizującą się w szeroko
        rozumianym doradztwie energetycznym i środowiskowym. Nasz Zespół
        stanowią managerowie oraz Specjaliści z wieloletnim doświadczeniem
        branżowym. Te wieloletnie doświadczenia pozwoliły na wypracowanie
        unikalnego know-how, które obecnie z sukcesem wykorzystujemy u naszych
        Klientów, zarówno z sektora przedsiębiorstw, jak i sektora publicznego i
        pozostałych.
      </p>
      <p class="about">
        Naszym szczególnym obszarem specjalizacji są projekty europejskie na
        rzecz efektywności energetycznej, transformacji, optymalizacji kosztów
        energii i ciepła.
      </p>
      <p class="about">
        Od wielu lat z sukcesem pomagamy również przedsiębiorcom w pozyskiwaniu
        środków unijnych na finansowanie projektów B+R, inwestycyjnych oraz
        związanych z transferem nowoczesnych technologii. Naszą przewagą jest
        fakt, że poza doradztwem dla klientów, jesteśmy również doświadczonym
        realizatorem dziesiątek własnych projektów, co powoduje, że mamy szersze
        spojrzenie na aspekty związane z ich późniejszą realizacją i przy
        tworzeniu wniosków zwracamy szczególną uwagę na komfort oraz
        bezpieczeństwo realizacji projektów, a nasz Zespół (link do o nas)
        posiada wieloletnie doświadczenie zawodowe w tym zakresie, zdobyte
        zarówno w Instytucjach publicznych jak i podmiotach prywatnych.
      </p>
    </div>
    <div class="offer">
      <div class="container">
        <h2>Oferta</h2>
        <div class="flex legal_offer">
          <div class="legal_offer-col">
            <h3 style="text-align: left; margin: 40px 0px">
              Fundusze europejskie i krajowe :
            </h3>
            <p>
              Usługa skierowana do wszystkich podmiotów szukających
              alternatywnych źródeł finansowania przedsięwzięć transformacji
              energetycznej, efektywności energetycznej, „zazieleniania”
              przedsiębiorstwa czy przestrzeni publicznej:
            </p>
            <br />
            <p>
              - audyt energetyczny wraz z analizą profilu produkcji energii i
              prognozy zużycia i kosztów po transformacji
            </p>
            <p>- pomoc w doborze odpowiedniego konkursu/programu</p>
            <p>
              - oddelegowanie przez cały okres współpracy doświadczonego Project
              Managera
            </p>
            <p>
              - pełne wsparcie na etapie prowadzonej oceny wniosku oraz
              podpisywania umowy
            </p>
            <p>
              - przygotowanie wszystkich dokumentów do uzyskania niezbędnych
              pozwoleń w procesie realizacji inwestycji, w tym decyzji
              środowiskowej, pozwolenia wodnoprawnego
            </p>
            <p>
              - kompleksowe przygotowanie wniosku o dofinansowanie wraz z
              niezbędnymi załącznikami
            </p>
          </div>
          <div class="legal_offer-img">
            <img src="@/assets/legal_offer.jpg" alt="" />
          </div>
        </div>

        <div style="margin-top: 40px" class="flex legal_offer">
          <div class="legal_offer-img">
            <img src="@/assets/energy.jpg" alt="" />
          </div>
          <div class="legal_offer-col">
            <h3 style="text-align: left; margin: 40px 0px">
              Audyty energetyczne i świadectwa energetyczne
            </h3>
            <p>
              Przygotowujemy świadectwa energetyczne i wykonujemy kompleksowe
              solidne audyty energetyczne zgodne z wymogami prawa krajowego i
              unijnego. Dokonujemy detalicznych analiz efektywności
              energetycznej, profilu zużycia energii. Wyróżnia nas indywidualne
              podejście do każdego zlecenia, nie wykonujemy analiz on- line w
              kilka godzin na podstawie zdjęć, ale zbieramy pełne dane i
              wykonujemy wieloaspektowe badania, do diagnozy używając
              wypracowanych narzędzi własnych. Każdy opracowany przez nas
              produkt to nie tylko dokument, który pozwala przedsiębiorcy
              spełnić wymóg ustawowy, ale jest narzędziem do planowania
              transformacji, zmian, oszczędności. Na życzenie klienta
              uzupełniamy analizę o proponowany model transformacji wraz ze
              wskazaniem możliwości zewnętrznego finansowania, w tym ze środków
              UE.
            </p>
          </div>
        </div>
        <div style="margin-top: 40px" class="flex legal_offer">
          <div class="legal_offer-col">
            <h3 style="text-align: left; margin: 40px 0px">
              Decyzje środowiskowe i pozwolenia wodnoprawne dla inwestycji
              energetycznych, środowiskowych i innych
            </h3>
            <p>
              Zespół doświadczonych specjalistów z doświadczeniem zarówno w
              realizacji inwestycji, w tym pozyskiwaniu decyzji, jak i w
              przeszłości wydających decyzje w administracji przygotuje
              dokumentację środowiskową i wodnoprawną na każdym etapie
              realizacji przedsięwzięcia. Mamy doświadczenie w przygotowywaniu
              dokumentów KIP, wykonywaniu analiz do raportów oddziaływania na
              środowisko, inwentaryzacji przyrodniczych, pozwoleń wodnoprawnych
              i wszystkich innych materiałów niezbędnych na etapie
              przygotowywania, jak i realizacji przedsięwzięcia. Znając obie
              strony procesu i administracyjną i inwestorską, potrafimy
              przygotować dokumenty nie tylko zgodnie z prawem, ale również
              zgodnie z przyjętą praktyką.
            </p>
          </div>
          <div class="legal_offer-img">
            <img src="@/assets/environment.jpg" alt="" />
          </div>
        </div>
      </div>
    </div>
  </div>
  <div class="footer">
    <div class="container">
      <p>
        Grupa SMD to Zespół doświadczonych Ekspertów, oferujących kompleksowe
        usługi w zakresie szeroko rozumianego doradztwa biznesowego i
        technologicznego. Zakres naszych usług obejmuje zarówno doradztwo
        związane z innowacyjnością i nowoczesnymi technologiami, jak również
        działania proinnowacyjne i prorozwojowe na rzecz firm, a także aspekty
        finansowe, księgowe, kadrowe oraz prawne.
      </p>
    </div>
  </div>
  <div class="footer_deep">
    <div class="container">
      <p>© 2021 Doradztwo SMD</p>
    </div>
  </div>
</template>

<script>
import Navbar from "./Navbar.vue";
import Widget from "./Consult.vue";

export default {
  name: "Home",
  components: { Navbar, Widget },
  setup() {},
};
</script>

<style lang="scss" scoped>
.about_title {
  margin-bottom: 40px;
}

p {
  color: #000;
  font-size: 16px;
}

h2 {
  text-align: left;
  position: relative;

  &::after {
    content: "";
    height: 4px;
    background: #f0a53d;
    bottom: -10px;
    left: 0;
    width: 50px;
    position: absolute;
  }
}

.about {
  margin-bottom: 40px;
}

.flex {
  gap: 30px;
}
</style>
