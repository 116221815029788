<template>
  <router-view />
</template>

<style lang="scss">
#app {
  font-family: 'Poppins', sans-serif;  
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  text-align: center;
  color: #000;
}


h1,h2,h3,h4,p {
  margin: 0
}

body {
  margin: 0;

}

html {
  line-height: 1.15;
  -webkit-text-size-adjust: 100%; 
}

</style>
