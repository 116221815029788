<template>
  <div class="nav">
    <div class="nav__container">
      <div class="nav__container-logo">
        <img src="@/assets/logo.png" class="logo" alt="logo" />
        <h2><strong>SMD</strong> GROUP</h2>
      </div>
      <div class="mobile-nav" :class="{ mobileWidth: navToggled }">
        <router-link to="/">
          <div class="mobile__container-items-link">
            <span>Strona główna</span>
          </div>
        </router-link>
    <router-link to="/zespol">
          <div class="mobile__container-items-link">
            <span>O nas</span>
          </div>
        </router-link>
        <div
          class="mobile__container-items-link"
          @click="offerMenu = !offerMenu"
        >
          <span>Oferta</span>
          <div class="sub_menu" v-if="offerMenu">
            <router-link to="/accounting"> Accounting </router-link>
            <router-link to="/consulting"> Consulting </router-link>
            <router-link to="/eco"> Eco </router-link>
          </div>
        </div>
        <router-link to="/kontakt">
          <div class="mobile__container-items-link">
            <span>Kontakt</span>
          </div>
        </router-link>

        <div class="mobile__container-items-link">
          <a
            href="https://www.facebook.com/SMD-Group-103097408349831"
            target="_blank"
          >
            <box-icon
              class="mr-3"
              type="logo"
              name="facebook-square"
              color="white"
            ></box-icon>
            Facebook
          </a>
        </div>
        <div class="mobile__container-items-link">
          <a
            href="https://www.linkedin.com/company/groupsmd/?viewAsMember=true"
            target="_blank"
            ><box-icon
              class="mr-3"
              name="linkedin-square"
              type="logo"
              color="white"
            ></box-icon>
            Linkedin
          </a>
        </div>
      </div>
      <div class="nav__container-items">
        <router-link to="/">
          <div class="nav__container-items-link">
            <span>Strona główna</span>
          </div>
        </router-link>
        <router-link to="/zespol">
          <div class="nav__container-items-link">
            <span>O nas</span>
          </div>
        </router-link>
        <div class="nav__container-items-link offer">
          <span>Oferta</span>
          <div class="hover-menu">
            <router-link to="/accounting"> Accounting </router-link>
            <router-link to="/consulting"> Consulting </router-link>
            <router-link to="/eco"> Eco </router-link>
          </div>
        </div>

        <router-link to="/kontakt">
          <div class="nav__container-items-link">
            <span>Kontakt</span>
          </div>
        </router-link>

        <div class="nav__container-items-link">
          <a
            href="https://www.facebook.com/SMD-Group-103097408349831"
            target="_blank"
          >
            <box-icon
              type="logo"
              name="facebook-square"
              color="white"
            ></box-icon>
          </a>
        </div>
        <div class="nav__container-items-link">
          <a
            href="https://www.linkedin.com/company/groupsmd/?viewAsMember=true"
            target="_blank"
            ><box-icon
              name="linkedin-square"
              type="logo"
              color="white"
            ></box-icon>
          </a>
        </div>
      </div>
      <box-icon
        name="menu-alt-right"
        color="white"
        class="burger"
        size="md"
        @click="navToggled = !navToggled"
      ></box-icon>
    </div>
  </div>
</template>

<script>
import { ref } from "vue";

export default {
  name: "Navbar",
  setup() {
    const offerMenu = ref(false);
    const navToggled = ref(false);

    return { offerMenu, navToggled };
  },
};
</script>

<style scoped lang="scss">
h2,
p,
h1 {
  color: #fff;
}

h2 {
  font-weight: 300;
  font-size: 34px;
}

.nav__container {
  display: flex;
  max-width: 1200px;
  align-items: center;
  padding: 40px 12px;
  width: 100%;
  box-sizing: border-box;
  margin: 0 auto;
  &-logo {
    display: flex;
    align-items: center;
  }
  &-items {
    display: flex;
    margin-left: auto;
    align-items: center;
    a {
      text-decoration: none;
      color: #fff;
      font-size: 24px;
      display: flex;
      align-items: center;
    }
    &-link {
      margin-left: 24px;
      display: flex;
      align-items: center;
    }
  }
}

.offer > span {
  font-size: 24px;
  color: #fff;
  cursor: pointer;
}

.offer {
  position: relative;
  &:hover .hover-menu {
    display: block;
  }
}

.hover-menu {
  position: absolute;
  top: 28px;
  min-width: 140px;
  left: 0;
  background: #6b6b6b;
  display: none;
  -webkit-box-shadow: 2px 5px 15px -1px rgba(0, 0, 0, 0.76);
  box-shadow: 2px 5px 15px -1px rgba(0, 0, 0, 0.76);
  a {
    padding: 10px;
    font-size: 16px;
    &:hover {
      opacity: 0.6;
    }
  }
}

.burger {
  margin-left: auto;
  display: none;
}

.mobile-nav {
  position: fixed;
  left: 0;
  top: 0;
  bottom: 0;
  z-index: 33;
  width: 250px;
  min-height: 100vh;
  transform: translateX(-100%);
  color: #fff;
  background: #1c1c1c;
  transition: 0.3s;
}

.mobileWidth {
  transform: translateX(0%);
}

.sub_menu {
  background: black;
  border-radius: 4px;
  margin-top: 12px;
}

.sub_menu a {
  padding: 10px;
}

.mr-3 {
  margin-right: 12px;
}

@media only screen and (max-width: 842px) {
  .burger {
    display: block;
  }

  .nav__container-items {
    display: none;
  }
  h2 {
    font-size: 22px;
  }
  .logo {
    width: 56px;
    height: auto;
    margin-right: 26px;
  }

  .mobile-nav > a {
    text-decoration: none;
    color: #fff;
  }

  .mobile__container-items-link {
    text-align: left;
    padding: 12px;
  }

  .mobile__container-items-link a {
    display: flex;
    align-items: center;
    color: white;
    text-decoration: none;
  }
}
</style>
