<template>
  <div class="home_page">
    <Navbar />
    <div class="container">
    <p class="home_page__description">
      Grupa SMD to Zespół doświadczonych Ekspertów, oferujących kompleksowe usługi w zakresie szeroko rozumianego doradztwa biznesowego, technologicznego, energetycznego i środowiskowego. Zakres naszych usług obejmuje zarówno doradztwo związane z innowacyjnością i nowoczesnymi technologiami, jak również działania proinnowacyjne i prorozwojowe na rzecz firm, a także aspekty finansowe, księgowe, kadrowe oraz prawne. Specjalizujemy się w projektach transformacji energetycznej i środowiskowej, w tym efektywności energetycznej, OZE, termomodernizacji.
    <br>
      Dzięki połączeniu kompetencji i interdyscyplinarności naszego Zespołu, Klient otrzymuje zawsze usługę „szytą na miarę”, dopasowaną do swoich indywidualnych potrzeb, we wszystkich kluczowych obszarach prowadzenia działalności gospodarczej. Zachęcamy do szczegółowego zapoznania się z naszą ofertą.
    </p>
  </div>

  <div class="container">
    <div class="home_page__boxes">
      <router-link to="/consulting">
      <div class="home_page__boxes-consulting">
        <img src="@/assets/logo-w.png" class="logo-w" />
        <h3><strong>SMD Consulting</strong></h3>
      </div>
      </router-link>
            <router-link to="/accounting">

       <div class="home_page__boxes-accounting">
        <img src="@/assets/logo-w.png" class="logo-w" />
        <h3><strong>SMD Accounting</strong></h3>
      </div>
            </router-link>
      <router-link to="/eco">

       <div class="home_page__boxes-legal">
        <img src="@/assets/logo-w.png" class="logo-w" />
        <h3><strong>SMD Eco</strong></h3>
      </div>
            </router-link>

  </div>
  </div>
  </div>
</template>

<script>
import Navbar from "./Navbar.vue"
export default {
  name: "Home",
  components: { Navbar },
  setup() {

    return { 

    }
  }

};
</script>

<!-- Add "scoped" attribute to limit CSS to this component only -->
<style scoped lang="scss">

</style>
