import { createRouter, createWebHistory } from "vue-router";
import Home from "../components/Home.vue";
import Consulting from "../components/Consulting.vue";
import Accounting from "../components/Accounting.vue";
import Contact from "../components/Contact.vue";
import Legal from "../components/Legal.vue";
import Team from "../components/Team.vue";

const routes = [
  {
    path: "/",
    name: "Home",
    component: Home,
  },
  {
    path: "/consulting",
    name: "Consulting",
    component: Consulting,
  },
  {
    path: "/accounting",
    name: "Accounting",
    component: Accounting,
  },
  {
    path: "/kontakt",
    name: "Contact",
    component: Contact,
  },
  {
    path: "/eco",
    name: "Legal",
    component: Legal,
  },
  {
    path: "/zespol",
    name: "Team",
    component: Team,
  },
];

const router = createRouter({
  history: createWebHistory(process.env.BASE_URL),
  routes,
});

export default router;
