<template>
  <router-link to="/kontakt">
    <div class="contact-msg">
      <img src="@/assets/logo.png" class="logo" />
      <div class="contact-msg-c">
        <box-icon
          type="solid"
          name="message-dots"
          color="white"
          class="msg-dots"
        ></box-icon>
        <p>Umow sie na bezpłatną konsultacje</p>
      </div>
    </div>
  </router-link>
</template>

<style scoped lang="scss">
.contact-msg {
  height: 100px;
  background: blue;
  cursor: pointer;
  position: fixed;
  bottom: 0;
  padding: 4px;
  border-radius: 12px;
  right: 20px;
  bottom: 20px;
  &-c {
    display: flex;
    align-items: center;
  }
  & p {
    font-size: 12px;
    margin-left: 8px;
  }
}

.logo {
  width: 40px;
}
</style>
