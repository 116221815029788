<template>
<Widget />
  <div class="consulting_page">
    <Navbar />
  </div>
  <div class="consulting_content">
    <div class="container">
      <h2 class="about_title">Kim jesteśmy?</h2>
      <p class="about">
        SMD jest Spółką consultingową specjalizującą się w szeroko rozumianym
        doradztwie biznesowym, technologicznym oraz badawczo-rozwojowym. Nasz
        Zespół stanowią managerowie oraz Specjaliści z wieloletnim
        doświadczeniem branżowym. Te wieloletnie doświadczenia pozwoliły na
        wypracowanie unikalnego know-how, które obecnie z sukcesem
        wykorzystujemy u naszych Klientów, zarówno z sektora MŚP, jak również
        dużych podmiotów.
      </p>
      <p class="about">
        Naszym szczególnym obszarem specjalizacji są innowacyjne technologie i
        procesy przemysłowe, związane m.in. z takimi obszarami jak: inteligentne
        sieci, technologie informacyjno-komunikacyjne oraz geoinformacyjne,
        automatyzacja i robotyzacja procesów technologicznych czy też
        inteligentne technologie kreacyjne. Współpracujemy również na co dzień
        ze Środowiskami Naukowymi w całym kraju, dzięki czemu jesteśmy
        w&nbsp;stanie wesprzeć naszych Klientów w sposób kompleksowy,
        niezależnie od branży czy technologii, nawet przy najbardziej złożonych
        i&nbsp;skomplikowanych zagadnieniach czy też projektach.
      </p>
      <p class="about">
        Od wielu lat z sukcesem pomagamy również przedsiębiorcom w pozyskiwaniu
        środków unijnych na finansowanie projektów B+R, inwestycyjnych oraz
        związanych z transferem nowoczesnych technologii. Naszą przewagą jest
        fakt, że poza doradztwem dla klientów, jesteśmy również doświadczonym
        realizatorem dziesiątek własnych projektów, co powoduje, że mamy szersze
        spojrzenie na aspekty związane z ich późniejszą realizacją i przy
        tworzeniu wniosków zwracamy szczególną uwagę na komfort oraz
        bezpieczeństwo realizacji projektów, a nasz Zespół posiada wieloletnie
        doświadczenie zawodowe w tym zakresie, zdobyte zarówno w Instytucjach
        publicznych jak i podmiotach prywatnych.
      </p>
    </div>
    <div class="container">
      <h2 class="offer_title">Oferta</h2>
      <div
        class="offer_accordion"
        v-for="(offer, index) in offers"
        :key="offer.id"
      >
        <button class="offer_accordion-button" @click="toggleOpen(index)">
          {{ offer.title }}
          <box-icon
            type="solid"
            name="chevron-down"
            class="chevron"
            v-if="offer.open == false"
          ></box-icon>
          <box-icon
            type="solid"
            name="chevron-up"
            class="chevron"
            v-if="offer.open == true"
          ></box-icon>
        </button>
        <div class="offer-img" v-if="offer.open == true">
          <img :src="require(`@/assets/${offer.img}`)" />
          <p class="accordion-description" v-html="offer.description"></p>
        </div>
      </div>
    </div>
  </div>
  <div class="footer">
    <div class="container">
      <p>
        Grupa SMD to Zespół doświadczonych Ekspertów, oferujących kompleksowe
        usługi w zakresie szeroko rozumianego doradztwa biznesowego i
        technologicznego. Zakres naszych usług obejmuje zarówno doradztwo
        związane z innowacyjnością i nowoczesnymi technologiami, jak również
        działania proinnowacyjne i prorozwojowe na rzecz firm, a także aspekty
        finansowe, księgowe, kadrowe oraz prawne.
      </p>
    </div>
  </div>
  <div class="footer_deep">
    <div class="container">
      <p>© 2021 Doradztwo SMD</p>
    </div>
  </div>
</template>
<script>
import { ref } from "vue";
import Navbar from "./Navbar.vue";
import Widget from "./Consult.vue";

export default {
  name: "Home",
  components: { Navbar, Widget },
  setup() {
    const offers = ref([
      {
        id: 1,
        title: "Audyty innowacyjności przedsiębiorstwa",
        description:
          "Usługa skierowana do podmiotów zainteresowanych wdrożeniem w swojej firmie innowacyjnych rozwiązań technologicznych. Przeprowadzony audyt pozwala ocenić potencjał i potrzeby przedsiębiorstwa w zakresie wdrożenia danej technologii oraz wskazać na te obszary działalności przedsiębiorcy, w których wdrożenie innowacji może przynieść wymierne korzyści dla firmy. <br><br> Usługa obejmuje: <br><br>- analizę możliwości zaspokojenia potrzeby zgłoszonej przez przedsiębiorcę przez innowacyjną technologię <br> - analizę struktury przedsiębiorstwa oraz aktualnie wykorzystywanych technologii/procesów pod kątem możliwości wdrożenia innowacji <br> - analizę pozycji rynkowej oraz wypływu wdrożenia innowacji na nią <br> - analizę SWOT planowanej działalności <br> - audyt działalności B+R przedsiębiorcy <br> - audyt jakości <br> - analizę systemu rozwoju zasobów ludzkich <br> - ocenę możliwości doskonalenia wydolności procesu produkcyjnego (Lean Manufacturing, Six Sigma)",
        open: false,
        img: "audit.jpg",
      },
      {
        id: 2,
        title: "Due diligence technologiczny",
        description:
          "Usługa skierowana do podmiotów posiadających innowacyjne technologie będące w fazie badań lub dojrzałości komercyjnej. Przeprowadzone due diligence technologiczne pozwala ocenić technologię pod kątem aktualnych trendów rynkowych, potencjału oraz szans i przeszkód w komercjalizacji i rozwoju. <br><br> Usługa obejmuje: <br><br> - ocenę i opis obecnego stanu badań i technologii <br> - analizę trendów prac B+R w rozpatrywanym obszarze <br> - porównanie danego rozwiązania w stosunku do obecnych i rozwijanych technologii <br> - analizę czynników decydujących o adaptacji na rynku oraz potencjalnych barier <br> - identyfikację właściwych rynków dla komercjalizacji <br> - rekomendację optymalnego scenariusza komercjalizacji",
        open: false,
        img: "tech_i.jpg",
      },
      {
        id: 3,
        title: "Ocena innowacyjności technologii",
        description:
          "Usługa skierowana do podmiotów zainteresowanych transferem i/lub wdrożeniem w swojej firmie innowacyjnych technologii. Przeprowadzona ocena pozwala ocenić stopień innowacyjności technologii, jej potencjał oraz ryzyka wdrożenia.  <br><br> Usługa obejmuje: <br><br> - analizę i ocenę stopnia innowacyjności technologii <br> - ocenę poziomu gotowości technologicznej <br> - porównanie z podobnymi lub substytucyjnymi rozwiązaniami <br> - określenie przewag konkurencyjnych w oparciu o posiadane właściwości, cechy i parametry technologiczne",
        open: false,
        img: "innovation_rating.jpg",
      },
      {
        id: 4,
        title: "Usługi proinnowacyjne i prorozwojowe",
        description:
          "Usługi w zakresie rozwoju biznesu i&nbsp;prowadzenia działalności gospodarczej, skierowane do podmiotów zainteresowanych zwiększeniem poziomu innowacyjności swoich firm, chcących wprowadzić do swojej działalności nowy lub istotnie ulepszony produkt lub usługę, nowy lub istotnie ulepszony proces, nową metodę marketingową lub nową metodę organizacyjną w przyjętych przez Przedsiębiorcę zasadach działania, w organizacji miejsca pracy lub w stosunkach z otoczeniem. Oferujemy kompleksowe wsparcie przedsiębiorców w tym zakresie, m.in. w obszarach takich jak:<br><br>- strategie rozwoju nowych produktów i usług<br>- strategie wdrażania innowacji, w tym doradztwo i pomoc w opracowaniu i wdrożeniu pilotażowym oraz pełnym innowacji<br>- analizy finansowe i ekonomiczne<br>- strategie komercjalizacji<br>- strategie marketingowe<br>- biznesplany<br>- studia wykonalności<br>- analizy popytowe<br>- analizy rynkowe<br>- analizy konkurencji<br>- analizy ryzyk<br>- badania użytkowników<br>- badania technologiczne<br>- rozwój zasobów ludzkich<br>- optymalizacja struktury organizacyjnej<br>- analizy alternatywnych ścieżek rozwoju<br>- strategie internacjonalizacji",
        open: false,
        img: "solutions.jpg",
      },
      {
        id: 5,
        title: "Fundusze europejskie",
        description:
          "Usługa skierowana do wszystkich firm szukających alternatywnych źródeł finansowania przedsięwzięć badawczych, inwestycyjnych oraz związanych z transferem technologii w postaci dofinansowania ze środków Unii Europejskiej. Usługa obejmuje:<br><br>- pomoc w doborze odpowiedniego konkursu/programu<br>- szczegółowe omówienie warunków i kryteriów udziału<br>- oddelegowanie przez cały okres współpracy doświadczonego Project Managera<br>- kompleksowe przygotowanie wniosku o dofinansowanie wraz z niezbędnymi Załącznikami<br>- pełną pomoc na etapie prowadzonej oceny wniosku oraz podpisywania umowy<br>- pomoc (w razie potrzeby) w pozyskaniu do projektu konsorcjantów branżowych, w tym Uczelni i Jednostek Naukowych oraz Członków Zespołu B+R o wymaganych kompetencjach i doświadczeniu",
        open: false,
        img: "eu_funding.jpg",
      },
    ]);

    function toggleOpen(idx) {
      offers.value[idx].open = !offers.value[idx].open;
    }
    return {
      offers,
      toggleOpen,
    };
  },
};
</script>
<style scoped lang="scss">
.about_title {
  margin-bottom: 40px;
}
h2 {
  text-align: left;
  position: relative;
  &::after {
    content: "";
    height: 4px;
    background: #bf1683;
    bottom: -10px;
    left: 0;
    width: 50px;
    position: absolute;
  }
}

p {
  color: #000;
  font-size: 16px;
}

.about {
  margin-bottom: 40px;
}

.offer-img {
  margin-top: 16px;
  & > img {
    border-radius: 16px;
    max-width: 320px;
    box-shadow: 0 10px 15px -3px rgba(0, 0, 0, 0.3),
      0 4px 6px -2px rgba(0, 0, 0, 0.05);
  }
}
</style>
