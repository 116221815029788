<template>
<Widget />
  <div class="accounting_page">
    <Navbar />
  </div>
  <div class="accounting_content">
    <div class="container">
      <h2 class="about_title">Kim jesteśmy?</h2>
      <p class="about">
        SMD Accounting to nowoczesne biuro rachunkowe zajmujące się kompleksową
        i rzetelną usługą księgową oraz obsługą kadr i płac. Nasz zespół
        stanowią specjaliści z wieloletnim doświadczeniem branżowym oraz
        znajomością prawa podatkowego, nabytym między innymi w instytucjach
        publicznych. To kombinacja wiedzy, doświadczenia oraz rozeznania we
        współczesnym biznesie. Dbamy o przejrzystą relację z klientem, a naszym
        fundamentem jest wzajemne zaufanie i zrozumienie oczekiwań w ramach
        naszej współpracy.
      </p>

      <p class="about">
        Na pierwszym miejscu stawiamy dobro klienta, czyli jego rozwój i cele
        strategiczne jakie sobie ustanowił. Dlatego nasz zespół ekspertów
        towarzyszy w każdej sytuacji oferując pełne wsparcie. Ponadto kreujemy
        nowoczesne podejście zgodne z dynamiką rozwoju świata biznesu, poprzez
        rozwój i wiedzę naszych ekspertów.
      </p>
      <p class="about">
        Wspieramy również podmioty takie jak startup, które potrzebują wiedzy i
        kompetencji z wielu obszarów na swoim starcie. Posiadamy wiedzę
        i&nbsp;doświadczenie również w obszarze rozliczania projektów
        finansowanych z UE przez co wspieramy naszych klientów w długotrwałym
        procesie, który ma ogromny wpływ na płynność finansową realizowanego
        projektu, który otrzymał dofinansowanie.
      </p>
    </div>

    <div class="offer">
      <div class="container">
        <h2>Oferta</h2>
        <h3>
          Dopasowując się do aktualnych potrzeb i oczekiwań naszych klientów
          oferujemy:
        </h3>
        <div class="grid-col">
          <div class="col-1">
            <p>
              Usługi księgowe, czyli kompleksowe wsparcie podatkowe oraz
              księgowe dla firm i spółek z o. o., spółek akcyjnych, spółek
              komandytowych z każdej branży, w tym:
            </p>
            <br />
            <p>
              - prowadzenie uproszczonej księgowości (KPiR, ryczałt od
              przychodów ewidencjonowanych)
            </p>
            <p>- prowadzenie ksiąg rachunkowych (księgi handlowe)</p>
            <p>- doradztwo podatkowe</p>
            <p>- sprawozdawczość</p>
            <p>- nadzór księgowy</p>
            <p>- prowadzenie akt</p>
            <p>
              - pomoc w rozliczaniu projektów finansowanych z UE i innych źródeł
              finansowania
            </p>
          </div>
          <div class="col-1-img">
            <img src="@/assets/col-1-img.jpeg" />
          </div>
          <div class="col-2-img">
            <img src="@/assets/col-2-img.jpeg" />
          </div>
          <div class="col-2">
            <p>
              Usługi kadrowo płacowe, czyli kompleksową obsługę z zakresu kadr i
              płac jako kluczowy czynnik, decydujący o sprawnym działaniu
              przedsiębiorstwa, w tym:
            </p>
            <br />
            <p>- prowadzenie akt osobowych pracowników</p>
            <p>- naliczanie płac</p>
            <p>- tworzenie i przesyłanie ZUS deklaracji</p>
            <p>- prowadzenie rozliczeń PEFRON</p>
            <p>- roczne deklaracje podatkowe</p>
            <p>- doradztwo w zakresie prawa pracy</p>
            <p>
              - pomoc w przygotowaniu przedsiębiorstwa do kontroli ZUS, Urzędu
              Skarbowego czy Państwowej Inspekcji Pracy
            </p>
            <p>- pracownicze Plany Kapitałowe</p>
          </div>
        </div>
      </div>
    </div>
  </div>
  <div class="footer">
    <div class="container">
      <p>
        Grupa SMD to Zespół doświadczonych Ekspertów, oferujących kompleksowe
        usługi w zakresie szeroko rozumianego doradztwa biznesowego i
        technologicznego. Zakres naszych usług obejmuje zarówno doradztwo
        związane z innowacyjnością i nowoczesnymi technologiami, jak również
        działania proinnowacyjne i prorozwojowe na rzecz firm, a także aspekty
        finansowe, księgowe, kadrowe oraz prawne.
      </p>
    </div>
  </div>
  <div class="footer_deep">
    <div class="container">
      <p>© 2021 Doradztwo SMD</p>
    </div>
  </div>
</template>

<script>
import Navbar from "./Navbar.vue";
import Widget from "./Consult.vue";

export default {
  name: "Accounting",
  components: { Navbar, Widget },
  setup() {},
};
</script>

<style lang="scss" scoped>
.grid-col {
  display: grid;
  grid-template-columns: repeat(2, minmax(0, 1fr));
  grid-gap: 80px;
}
h3 {
  text-align: left;
  margin: 40px 0px;
}
.about_title {
  margin-bottom: 40px;
}
p {
  color: #000;
  font-size: 16px;
}
h2 {
  text-align: left;
  position: relative;
  &::after {
    content: "";
    height: 4px;
    background: #3f75c1;
    bottom: -10px;
    left: 0;
    width: 50px;
    position: absolute;
  }
}

.about {
  margin-bottom: 40px;
}

.col-1-img,
.col-2-img {
  display: flex;
  align-items: center;
}

.col-1-img > img {
  width: 100%;
  height: auto;
  display: block;
  box-shadow: 0 10px 15px -3px rgba(0, 0, 0, 0.1),
    0 4px 6px -2px rgba(0, 0, 0, 0.05);
  border-radius: 24px;
  object-fit: fit-content;
}

.col-2-img > img {
  width: 100%;
  height: auto;
  display: block;
  box-shadow: 0 10px 15px -3px rgba(0, 0, 0, 0.1),
    0 4px 6px -2px rgba(0, 0, 0, 0.05);
  border-radius: 24px;
  object-fit: fit-content;
}

@media only screen and (max-width: 768px) {
  .grid-col {
    grid-template-columns: 1fr;
    grid-gap: 24px;
  }
  .col-1-img {
    order: -1;
  }
}
</style>