<template>
  <Widget />
  <div class="team_page">
    <Navbar />
  </div>
  <div class="team_content">
    <h1 class="main-title">Zespół SMD</h1>
    <div class="container">
      <div class="consulting">
        <div class="team-grid">
          <div class="team-card">
            <div class="team-card-image">
              <img src="@/assets/zdjecie_pawlos_mandzios.png" alt="" />
            </div>
            <div class="team-card-desc">
              <p>
                <strong>Pawlos Mandzios</strong> - Manager z ponad 15-letnim
                doświadczeniem w zakresie zakładania oraz rozwijania
                różnorodnych działalności gospodarczych. Od wielu lat związany z
                branżą nowych technologii, HR i gamingu. Z sukcesem tworzy
                startupy oraz przedsiębiorstwa, które funkcjonują na rynku jako
                liderzy swojej branży. Ekspert z zakresu transferu technologii,
                komercjalizacji efektów przeprowadzonych prac B+R oraz wdrażania
                na rynek innowacyjnych produktów. Specjalizuje się w tworzeniu
                strategii komercjalizacji o zasięgu ponadnarodowym, planów
                marketingowych, strategii rozwoju przedsiębiorstwa, analiz i
                badań rynku oraz pozyskiwaniu funduszy na rozwój innowacyjnych
                pomysłów, zarówno w zakresie funduszy publicznych (UE), jak
                również od funduszy VC, business angels a także wykorzystania
                zwrotnych instrumentów finansowych.
              </p>
            </div>
          </div>
          <div class="team-card">
            <div class="team-card-image">
              <img src="@/assets/Zdjecie_KL_2022.jpg" />
            </div>
            <div class="team-card-desc">
              <p>
                <strong>Krzysztof Leśniewski</strong> - Manager z blisko
                15-letnim doświadczeniem w zakresie tworzenia, rozwijania i
                wdrażania nowoczesnych technologii oraz prowadzenia działań
                inkubacyjnych i akceleracyjnych na rzecz startupów. W tym czasie
                przeprowadził zarówno dla dużych podmiotów, jak również z
                sektora MŚP szereg analiz z zakresu m.in. analizy rynku,
                potencjalnych odbiorców nowych produktów, istniejącej
                konkurencji, potencjału i strategii komercjalizacji, stopnia
                innowacyjności produktów, wdrażania i rozwoju innowacji, etc.
                Współpracował z licznymi Instytucjami Otoczenia Biznesu oraz
                Instytucjami Administracji Publicznej w zakresie projektów
                dedykowanych innowacjom w biznesie. Specjalizuje się w
                realizacji przedsięwzięć inwestycyjnych oraz
                badawczo-rozwojowych, w swojej karierze zarządzał jako Project
                Manager ponad 100 projektami z ww. obszarów, finansowanych
                zarówno ze środków prywatnych jak i publicznych, w tym UE.
              </p>
            </div>
          </div>

          <div class="team-card">
            <div class="team-card-image">
              <img src="@/assets/annamoskwa.jpg" alt="" />
            </div>
            <div class="team-card-desc">
              <p>
                <strong>Anna Moskwa</strong> - Manager z ponad 20-letnim
                doświadczeniem w zakresie zarządzania podmiotami i projektami w
                otoczeniu krajowym i międzynarodowym. Pierwsza kobieta minister
                klimatu i środowiska w Polsce, wcześniej wiceminister od
                gospodarki wodnej. Posiada rozległą wiedzę w zakresie
                energetyki, w tym OZE; ochrony środowiska, w tym decyzji
                środowiskowych oraz gospodarki wodnej, w tym prawa wodnego.
                Zarządzała międzynarodowym projektem morskiej energetyki
                wiatrowej. Dzięki pracy w każdym sektorze doskonale zna
                uwarunkowania rzeczywistości publicznej, jak i prywatnej.
                Specjalizuje się w funduszach unijnych i krajowych w obszarze
                energetyki, klimatu i środowiska. Ekspert w zakresie
                termomodernizacji, efektywności energetycznej, „zazieleniania”
                przedsiębiorstw i przestrzeni publicznej, ESG, sprawiedliwej
                transformacji szytej na miarę, innowacjach.
              </p>
            </div>
          </div>
        </div>
      </div>

      <div class="accounting">
        <div class="team-grid">
          <div class="team-card">
            <div class="team-card-image">
              <img src="@/assets/paulina_marynowska.jpeg" alt="" />
            </div>
            <div class="team-card-desc">
              <p>
                <strong>Paulina Marynowska</strong> - Ekspertka z ponad
                10-letnim doświadczeniem w zakresie finansów i rachunkowości.
                Przez wiele lat związana z administracją państwową, gdzie
                zajmowała się szeroko rozumianymi zagadnieniami podatkowymi.
                Nabytą wiedzę i umiejętności skutecznie wykorzystuje od kilku
                lat w biznesie, kompleksowo wspierając Przedsiębiorców w
                zakresie podatkowym, księgowym oraz kadrowo-płacowym. Jako
                wieloletnia Ekspertka ds. finansów i rozliczeń w projektach
                współfinansowanych ze środków UE, posiada również praktyczną
                wiedzę i doświadczenie w prowadzeniu i rozliczaniu ww. projektów
                od strony finansowej, zarówno społecznych, jak również
                infrastrukturalnych, badawczo-rozwojowych oraz inwestycyjnych.
              </p>
            </div>
          </div>
          <div class="team-card">
            <div class="team-card-image">
              <img src="@/assets/agnieszka_kilis.jpeg" alt="" />
            </div>
            <div class="team-card-desc">
              <p>
                <strong>Agnieszka Kiliś</strong> - Ekspertka z ponad 10-letnim
                doświadczeniem w zakresie prowadzenia i rozliczania projektów
                współfinansowanych zarówno ze środków UE, jak również
                publicznych oraz prywatnych. Kierowniczka licznych projektów
                realizowanych w Programach takich jak m.in. POKL, POWER, POIR,
                RPO, FIO, ASOS, MKiDN, LGD. Specjalizuje się w obszarach
                związanych z pomocą publiczną i de minimis, zamówieniami
                publicznymi, monitoringiem i sprawozdawczością projektową oraz
                kontrolą i audytami projektów.
              </p>
            </div>
          </div>
        </div>
      </div>
      <!--
      <div class="legal">
        <h2 class="legal_title">SMD Legal</h2>
        <div class="team-grid">
          <div class="team-card">
            <div class="team-card-image">
              <img src="@/assets/magdalena_klys.jpg" />
            </div>
            <div class="team-card-desc">
              <p>
                <strong>Magdalena Kłys-Korzeniowska</strong> - Adwokat z ponad
                20-letnim doświadczeniem zawodowym. Ekspert z zakresu prawa
                cywilnego, gospodarczego, majątkowego i energetycznego.
                Specjalizuje się w prawie własności intelektualnej oraz prawie
                inwestycyjnym, a także w zakresie przekształceń, połączeń i
                podziałów Spółek, due diligence prawnym, prawach autorskich oraz
                kompleksowej obsłudze procesu inwestycyjnego.
              </p>
            </div>
          </div>
        </div>
      </div>
      -->
    </div>
  </div>
  <div class="footer">
    <div class="container">
      <p>
        Grupa SMD to Zespół doświadczonych Ekspertów, oferujących kompleksowe
        usługi w zakresie szeroko rozumianego doradztwa biznesowego,
        technologicznego, energetycznego i środowiskowego. Zakres naszych usług
        obejmuje zarówno doradztwo związane z innowacyjnością i nowoczesnymi
        technologiami, jak również działania proinnowacyjne i prorozwojowe na
        rzecz firm, a także aspekty finansowe, księgowe, kadrowe oraz prawne
        .Specjalizujemy się w projektach transformacji energetycznej i
        środowiskowej, w tym efektywności energetycznej, OZE, termomodernizacji.
      </p>
    </div>
  </div>
  <div class="footer_deep">
    <div class="container">
      <p>© 2021 Doradztwo SMD</p>
    </div>
  </div>
</template>

<script>
import Navbar from "./Navbar.vue";
import Widget from "./Consult.vue";

export default {
  name: "Team",
  components: { Navbar, Widget },
  setup() {},
};
</script>

<style lang="scss" scoped>
.consulting_title {
  text-align: left;
  position: relative;
  &::after {
    content: "";
    height: 4px;
    background: #bf1683;
    bottom: -10px;
    left: 0;
    width: 50px;
    position: absolute;
  }
}

.team-card-desc {
  text-align: left;
  & p {
    color: #000;
  }
}

.team-grid {
  display: grid;
  grid-template-columns: repeat(1, minmax(0, 1fr));
}
.team-card {
  border-radius: 16px;
  display: flex;
  margin-top: 80px;
  gap: 40px;
  align-items: flex-start;

  &-image {
    & img {
      width: 250px;
      height: 400px;
      object-fit: cover;
      border-radius: 16px;
      -webkit-box-shadow: 0px 8px 24px 0px rgba(66, 68, 90, 1);
      -moz-box-shadow: 0px 8px 24px 0px rgba(66, 68, 90, 1);
      box-shadow: 0px 8px 24px 0px rgba(66, 68, 90, 1);
    }
  }
}

.accounting_title {
  text-align: left;
  margin-top: 60px;
  position: relative;
  &::after {
    content: "";
    height: 4px;
    background: #3f75c1;
    bottom: -10px;
    left: 0;
    width: 50px;
    position: absolute;
  }
}

.legal_title {
  text-align: left;
  margin-top: 60px;
  position: relative;
  &::after {
    content: "";
    height: 4px;
    background: #f0a53d;
    bottom: -10px;
    left: 0;
    width: 50px;
    position: absolute;
  }
}

.main-title {
  margin-bottom: 40px;
}

@media only screen and (max-width: 600px) {
  .team-card {
    flex-direction: column;
  }
  .team-card-image {
    display: block;
    margin: 0 auto;
  }
}
</style>
